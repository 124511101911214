import React, { useReducer, useState } from 'react'

const AppContext = React.createContext();


const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SALES_REPORT_LIST':
          return {...state, REPORT_LIST: action.payload}
        default:
            break;
    }
}


export const AppProvider = ({ children }) => {
    // const [counter, setCounter] = useState(0)

  
    const [state, dispatch] = useReducer(reducer, { SET_SALES_REPORT_LIST: [], REPORT_LIST: [] });



    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;