import { BrowserRouter as Router, Route, Link, useRouteMatch, useParams, Switch } from "react-router-dom";

// import Homepage from "./components/Homepage";
import Login from './screens/Login'
import Register from "./screens/Register";
import Dashboard from "./screens/Dashboard";
import Profile from "./screens/Profile";
import axios from "axios";
import ENV from "./screens/ENV";
import { useEffect, useMemo, useState } from "react";
import Downline from "./screens/Downline";
import Direct from "./screens/Direct";
import Unpaid from "./screens/Unpaid";
import Product from "./screens/Product";
import InventoryAssignment from "./screens/InventoryAssignment";
import Sales from "./screens/Sales";
import Printer from "./screens/Printer";
import SalesReport from "./screens/SalesReport";
import { AppProvider } from "./screens/context/AppContext";
import Inventory from "./screens/Inventory";
import InventoryReview from "./screens/InventoryReview";
import InventoryCategory from "./screens/InventoryCategory";
import InventoryCurrent from "./screens/InventoryCurrent";
import AddCustomerPage from "./screens/AddCustomerPage";
import InventoryPending from "./screens/InventoryPending";
import UserControl from "./screens/UserControl";
import Stockin from "./screens/Stockin";
import Employee from "./screens/Employee";
import Invoices from "./screens/Invoices";
import Scanner from "./screens/component/inventory/scanner";
import Customer from "./screens/Customer";
import CustomerPurchases from "./screens/CustomerPurchases";
import SalesReportDetails from "./screens/SalesReportDetails";
import Reservation from "./screens/Reservation";
import AccountReceivable from "./screens/AccountReceivable";


function App() {
    const [userInfo, setUserInfo] = useState(null);
    // localStorage.removeItem('user_id')
    // localStorage.clear()
    // console.log(localStorage.getItem('user_id'))


    const AllModules = false;
    const InventoryModules = true; // only

    if (AllModules) {
        return (
            <AppProvider>
                <Router>
                    <Switch>
                        <Route exact={true} path="/">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryCurrent userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/customer">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Customer userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/customer/purchases/:id_customer">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <CustomerPurchases userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/product">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Product userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/stockin">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Stockin userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        {/* <Route exact={true} path="/inventory">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryCurrent userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/current">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryCurrent userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/review">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryAssignment userInfo={userInfo} hide={true} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/review/:user_id/:cat_id">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryCategory userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/review/:cat_id/:product_id/:year">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryReview userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/review/:user_id/:cat_id/:product_id">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryReview userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/review/:user_id/:cat_id/:product_id/:year">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryReview userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route> */}

                        <Route exact={true} path="/employee">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Employee userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/sales">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Sales userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/accounts/receivable">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <AccountReceivable userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/reservation">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Reservation userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/reservation/:reservation_id">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Reservation userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/reservation/:reservation_id/:path">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Reservation userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/reservation/:reservation_id/:path/:path_payment">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Reservation userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/reservation/:reservation_id/:path/:path_payment/:method_payment">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Reservation userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/reservation/:reservation_id/:path/:path_payment/:method_payment/:option_payment">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Reservation userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/report/invoice">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <Invoices userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/report/sales">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <SalesReport userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/report/sales/:sales_id">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <SalesReportDetails userInfo={userInfo} />
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact={true} path="/scanner">
                            <Scanner />
                        </Route>
                    </Switch>
                </Router>
            </AppProvider>
        )
    }

    if (InventoryModules) {
        return (
            <AppProvider>
                <Router>
                    <Switch>
                        <Route exact={true} path="/">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryCurrent userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryCurrent userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/current">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryCurrent userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/review">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryAssignment userInfo={userInfo} hide={true} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/review/:user_id/:cat_id">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryCategory userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/review/:cat_id/:product_id/:year">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryReview userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/review/:user_id/:cat_id/:product_id">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryReview userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact={true} path="/inventory/review/:user_id/:cat_id/:product_id/:year">
                            {
                                localStorage.getItem('user_id') !== null ?
                                    <>
                                        {
                                            Number(localStorage.getItem('user_id')) === 1 || Number(localStorage.getItem('user_id')) === 2 ?
                                                <InventoryReview userInfo={userInfo} />
                                                :
                                                <Inventory userInfo={userInfo} />
                                        }
                                    </>
                                    :
                                    <Login />
                            }
                        </Route>
                    </Switch>
                </Router>
            </AppProvider>
        )
    }






}

export default App;
