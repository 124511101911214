import React from 'react'
//stgcinc888@gmail.com

const SUBSCRIPTION = '';

// const PRINTER_URL = 'https://inv.stgc.tech/api/print_receipt.php';
// const PRINTER_URL = 'https://cyong.store/api/print_receipt.php';
const PRINTER_URL = 'http://localhost/stgc/api/print_receipt.php';


// const DOMAIN = 'http://localhost/stgc/';
// const DOMAIN = 'https://stgc.tech/';
// const DOMAIN = 'http://192.168.14.116/pluggedin/php/';
// const DOMAIN = 'https://directselling.gblessnetwork.net/';
const DOMAIN    = 'https://cyong.store/api/';
// const DOMAIN = 'http://bqmhospital.com/';
// const DOMAIN = 'https://pluggedin.tagaytaycafe.org/php/';
// const DOMAIN = 'http://192.168.1.99/';  ///stgc
// const DOMAIN = 'http://192.168.1.10/stgc/';
// const DOMAIN = 'http://192.168.1.10:99/';
// const DOMAIN = 'https://inv.stgc.online/';
// const DOMAIN = 'https://inv.stgc.tech/';

// const IMG_URL   = 'https://stgc.tech/pages/';
// const IMG_URL = 'http://192.168.1.99/images/';  ///stgc
const IMG_URL   = 'https://cyong.store/pages/';
// const IMG_URL = 'https://x.stgc.online/pages/';
// const IMG_URL = 'http://localhost/stgc/images/';

const SRPPRICE = 0.05;
const VATPRICE = 0.20;
const DISCOUTEDPRICE = 0.04;

const ENV =  {
    DOMAIN,
    IMG_URL,
    PRINTER_URL,
    
    SRPPRICE,
    VATPRICE,
    DISCOUTEDPRICE
}

export default ENV
