// CardCaptureView.js
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import Tesseract from 'tesseract.js';

const CardCaptureView = () => {
  const [image, setImage] = useState(null);
  const [scannedData, setScannedData] = useState('');
  const webcamRef = useRef(null);

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setImage(imageSrc);
      processImage(imageSrc);
    }
  };

  const processImage = (imageSrc) => {
    Tesseract.recognize(
      imageSrc,
      'eng',
      { logger: info => console.log(info) } // Optional logging
    ).then((data) => {
        console.log(data)
      setScannedData(data.text);
    }).catch(err => {
      console.error('OCR error:', err);
    });
  };

  return (
    <div>
      <h1>Card Capture</h1>
      <div>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width="50%"
          height="auto"
        />
      </div>
      <button onClick={captureImage}>Capture Image</button>
      {image && (
        <div>
          <h2>Captured Image:</h2>
          <img src={image} alt="Captured" style={{ width: '50%', maxHeight: '50%' }} />
        </div>
      )}
      {scannedData && (
        <div>
          <h3>Scanned Data:</h3>
          <p>{scannedData}</p>
        </div>
      )}
    </div>
  );
};

export default CardCaptureView;
